import { useEffect, useMemo, useState } from "react";
import { api_postListMine, api_postTradeDoneMine } from "../../api/post";
import { TPost } from "../../lib/types/post.type";
import useIsMobile from "../../lib/hooks/useIsMobile";
import Pagination from "../../components/Pagination";
import PostDetail from "../../components/Post/PostDetail";
import BaseButtonOutline from "../../components/Button/Outline";
import {
  atom__confirmModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useSetRecoilState } from "recoil";

const MyPostsPage = () => {
  const isMobile = useIsMobile();

  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TPost[]>([]);

  const limit = useMemo(() => 3, []);

  useEffect(() => {
    _fetch({
      page: 1,
      needTotalCount: "y",
    });
  }, []);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
    userKind?: string;
  }) => {
    api_postListMine({
      from: limit * (page - 1),
      limit,
      needTotalCount,
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  const requestTradeDone = async (postId: string) => {
    r__setConfirmModalInfo({
      title: "거래완료 요청",
      desc: [
        "선택하신 매물에 대해 거래완료 요청을 접수하시겠습니까?",
        "해당 매물은 일시적으로 비활성화됩니다.",
        "관리자의 최종확인 후 거래완료처리가 진행됩니다.",
      ],
      theme: "info",
      onClickBtnRight: () => {
        api_postTradeDoneMine(postId).then((res) => {
          if (!res) return;
          r__setConfirmModalInfo(null);
          r__setSuccessModalInfo({ desc: ["거래완료 요청이 접수되었습니다."] });

          setCurrentPage(1);
          _fetch({ page: 1, needTotalCount: "y" });
        });
      },
      btnRightText: "확인",
    });
  };

  return (
    <div
      className="flex-col"
      style={{
        height: "100%",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: isMobile ? "20px" : "100px",
        paddingRight: isMobile ? "20px" : "100px",
        overflowY: "auto",
      }}
    >
      <h2>등록 매물</h2>
      <div
        className={"flex-row-start-center"}
        style={{
          width: "100%",
          marginTop: "20px",

          flex: 1,
          flexWrap: isMobile ? "wrap" : "nowrap",
        }}
      >
        {list.map((post, i) => (
          <div
            key={post.id}
            className="flex-col-start-center"
            style={
              isMobile
                ? {
                    width: "100%",
                    minWidth: "360px",
                    height: "fit-content",
                    marginBottom: "10px",
                  }
                : {
                    width: "480px",
                    height: "100%",
                    marginRight: i !== list.length - 1 ? "5%" : 0,
                  }
            }
          >
            <PostDetail
              post={post}
              style={{
                width: "100%",
                flex: 1,
                overflowY: "auto",
                marginBottom: "20px",
              }}
              wished={null}
            />

            <BaseButtonOutline
              text="거래 완료"
              onClick={() => requestTradeDone(post.id)}
              style={{ width: "80%", marginBottom: "40px" }}
            />
          </div>
        ))}
      </div>

      <Pagination
        pagePerGroup={10}
        itemPerPage={3}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};
export default MyPostsPage;
