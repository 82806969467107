import { TMembership, TUserMembership } from "./membership.type";
import { TOffice } from "./office.type";
import { TPost, TPostListItemWithStatus } from "./post.type";

export type TMe = {
  id: string;
  createdAt: string;
  name: string;
  uid: string;
  level: number;
  phone: string;
  email: string;
  profileImgUrl: string | null;
  isSale: boolean;
  unreadNotification: boolean;
  notificationStopDate: string | null;

  officeId: string;
  office?: TOffice;

  officeChanges: {
    status: "pending" | "accept" | "deny";
    changeOffice: TOffice;
  }[];

  activeMembershipId: string | null;
  activeMembership?: TUserMembership;
};

export type TRegisterPayload = {
  officeId?: string;
  membershipId: string;
  user: {
    uid: string;
    pw: string;
    name: string;
    phone: string;
    email: string;
    officeHp: string;
    imageUrlBusiness?: string;
    imageUrlOffice: string;
    imageUrlCert?: string;
  };
  userKind: string; // "개업공인중개사" | "소속공인중개사" | "중개보조원";
};

export type TEmployeeListItem = Pick<
  TMe,
  "id" | "phone" | "name" | "level" | "profileImgUrl"
> & {
  postCount: number;
};
export type TEmployeeDetail = Pick<
  TMe,
  "id" | "phone" | "name" | "level" | "profileImgUrl" | "createdAt"
>;

export const USER_LEVEL_TO_LABEL: Record<number, string> = {
  100: "개업공인중개사",
  70: "소속공인중개사",
  40: "중개보조원",
  20: "영업직원",
};
export const USER_LEVEL: Record<string, number> = {
  개업공인중개사: 100,
  소속공인중개사: 70,
  중개보조원: 40,
  영업직원: 20,
};

export type TUserDetailRes = {
  user: {
    id: string;
    name: string | null;
    level: number;
    phone: string | null;
    profileImgUrl: string | null;
  };
  office: TOffice & { badgeWorth: number };
  posts: TPost[];
};
