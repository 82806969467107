import { Link, useLocation, useNavigate } from "react-router-dom";
import iconLogo from "../../../assets/logo.png";
import iconProfileDefault from "../../../assets/profile-default.png";
import iconMenu from "../../../assets/menu.png";
import iconNotiNew from "../../../assets/noti-new.png";
import iconNoti from "../../../assets/noti.png";
import { GNB_MENU_MOBILE } from "./menu";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { atom__me } from "../../../lib/recoil/common.atom";
import {
  atom__loginModalInfo,
  atom__menuModalInfo,
  atom__notificationModalInfo,
} from "../../../lib/recoil/modal.atom";
import ModalNotification from "../../Modal/Notification";

const GlobalNavigationBarMobile = () => {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();

  const r__me = useRecoilValue(atom__me);
  const r__setLoginModalInfo = useSetRecoilState(atom__loginModalInfo);
  const r__setMenuModalInfo = useSetRecoilState(atom__menuModalInfo);
  const r__setNotiModalInfo = useSetRecoilState(atom__notificationModalInfo);

  if (pathName === "/map")
    return (
      <section className="gnb-wrapper">
        <div
          className="gnb"
          style={{
            padding: "10px",
          }}
        >
          <img
            src={iconMenu}
            style={{ width: "28px", height: "28px", cursor: "pointer" }}
            onClick={() => {
              r__setMenuModalInfo(true);
            }}
          />

          <div className="flex-row-center-center">
            <p className="text-small bold" style={{ color: "var(--brown)" }}>
              고객센터
            </p>
            <div
              className="flex-row-center-center"
              style={{ height: "100%", margin: "0 20px", cursor: "pointer" }}
              onClick={() => {
                if (!r__me) return;
                if (!r__me.activeMembershipId) return;
                r__setNotiModalInfo((prev) => (prev ? null : true));
              }}
            >
              <img
                src={r__me?.unreadNotification ? iconNotiNew : iconNoti}
                style={{ width: "28px", height: "32px" }}
              />
            </div>
            <div
              className="flex-row-center-center"
              style={{ height: "100%", cursor: "pointer" }}
              onClick={() => {
                // 로그인 전이면 로그인모달 표시, 로그인 후면 내정보 페이지로 이동
                if (!r__me) {
                  r__setLoginModalInfo({});
                  return;
                }

                navigate("/my/profile");
              }}
            >
              <img
                src={r__me?.profileImgUrl || iconProfileDefault}
                style={{ width: "36px", height: "36px", borderRadius: "36px" }}
              />
            </div>
          </div>
        </div>
      </section>
    );

  return (
    <section className="gnb-wrapper">
      <div
        className="gnb"
        style={{
          padding: "10px",
        }}
      >
        <img
          src={iconMenu}
          style={{ width: "28px", height: "28px", cursor: "pointer" }}
          onClick={() => {
            r__setMenuModalInfo(true);
          }}
        />

        <p className="text-medium bold">
          {GNB_MENU_MOBILE.reduce<{ path: string; name: string }[]>(
            (acc, cur) => [...acc, ...cur.items],
            []
          ).find((m) => pathName.startsWith(m.path))?.name ?? ""}
        </p>
        <span
          style={{ width: "28px", height: "28px", backgroundColor: "#fff" }}
        ></span>
      </div>

      {pathName !== "/map" && <ModalNotification />}
    </section>
  );
};

export default GlobalNavigationBarMobile;
