import { useEffect, useState } from "react";
import iconArrowLeft from "../assets/arrow-left-yellow.png";
import iconArrowRight from "../assets/arrow-right-yellow.png";

interface IProps {
  /** 페이지네이션 ui에 한번에 최대 몇개의 페이지버튼을 보여줄지 */
  pagePerGroup: number;
  /** 한페이지의 최대 아이템수 */
  itemPerPage: number;
  /** 총 아이템수 */
  totalItem: number;
  /** 현재 페이지 */
  currentPage: number;
  /** 새로운 페이지 클릭 */
  onClickPage: (page: number) => void;

  style?: React.CSSProperties;
}
const Pagination = (props: IProps) => {
  /** 총 페이지 수 */
  const [totalPage, setTotalPage] = useState(1);
  /** 총 페이지그룹 수 */
  const [totalGroup, setTotalGroup] = useState(1);
  /** 현재 페이지그룹 */
  const [currentGroup, setCurrentGroup] = useState(1);
  const [currentPageList, setCurrentPageList] = useState([1]);

  useEffect(() => {
    const { pagePerGroup, itemPerPage, totalItem, currentPage } = props;

    const _totalPage = totalItem === 0 ? 1 : Math.ceil(totalItem / itemPerPage);
    const _totalGroup = Math.ceil(_totalPage / pagePerGroup);
    const _currentGroup = Math.ceil(currentPage / pagePerGroup);
    setTotalPage(_totalPage);
    setTotalGroup(_totalGroup);
    setCurrentGroup(_currentGroup);

    const currentPageListStart = 1 + (_currentGroup - 1) * pagePerGroup;
    /** 현재 페이지그룹 속 페이지 수. 마지막 그룹이 아니라면 pagePerGroup 값 그대로. */
    let currentPageListLength = pagePerGroup;
    if (_currentGroup === _totalGroup) {
      /** 마지막 페이지그룹의 최대 페이지넘버. 그룹3에 pagePerGroup=10이라면 30이 됨. */
      const maxPageOfLastGroup = _currentGroup * pagePerGroup;
      /** totalPage=27이라면 페이지그룹은 21~27까지이므로 length = 7 */
      currentPageListLength = pagePerGroup - (maxPageOfLastGroup - _totalPage);
    }

    const _currentPageList = Array.from(
      { length: currentPageListLength },
      (x, i) => i + currentPageListStart
    );
    setCurrentPageList(_currentPageList);
  }, [props]);

  const prev = () => {
    /** 이전 페이지그룹의 마지막 페이지번호 */
    const newPage = (currentGroup - 1) * props.pagePerGroup;
    console.log("newPage(prev): ", newPage);
    props.onClickPage(newPage);
  };
  const next = () => {
    /** 다음 페이지그룹의 첫 페이지번호 */
    const newPage = 1 + (currentGroup + 1) * props.pagePerGroup;
    console.log("newPage(next): ", newPage);
    props.onClickPage(newPage);
  };

  return (
    <div
      className="flex-row-center-center pagination-wrapper"
      style={props.style}
    >
      {currentGroup > 1 ? (
        <button className="pagination-prev" onClick={prev}>
          <img src={iconArrowLeft} />
        </button>
      ) : (
        <span style={{ width: "36px" }}></span>
      )}

      {currentPageList.map((pageNum) => {
        return (
          <button
            key={pageNum}
            onClick={() => {
              if (props.currentPage === pageNum) return;
              props.onClickPage(pageNum);
            }}
            className={`pagination-num ${
              props.currentPage === pageNum ? "pagination-num-current" : ""
            }`}
          >
            {pageNum}
          </button>
        );
      })}

      {currentGroup < totalGroup ? (
        <button className="pagination-next" onClick={next}>
          <img src={iconArrowRight} />
        </button>
      ) : (
        <span style={{ width: "36px" }}></span>
      )}
    </div>
  );
};

export default Pagination;
