import { atom } from "recoil";
import { TBadge } from "../types/badge.type";

export const atom__successModalInfo = atom<{
  title?: string;
  desc: string[];
  onClickBtn?: () => void;
  btnText?: string;
} | null>({
  key: "successModalInfo",
  default: null,
});

export const atom__alertModalInfo = atom<{
  title: string;
  desc: string[];
  onClickBtn?: () => void;
  btnText?: string;
  theme?: "alert" | "info";
} | null>({
  key: "alertModalInfo",
  default: null,
});

export const atom__simpleConfirmModalInfo = atom<{
  title?: string;
  desc: string[];
  onClickBtnLeft: () => void;
  onClickBtnRight: () => void;
  btnLeftText?: string;
  btnRightText?: string;
  theme?: "alert" | "info";
} | null>({
  key: "simpleConfirmModalInfo",
  default: null,
});

export const atom__confirmModalInfo = atom<{
  title: string;
  desc: string[];
  onClickBtnLeft?: () => void;
  onClickBtnRight: () => void;
  btnLeftText?: string;
  btnRightText?: string;
  theme?: "alert" | "info";
} | null>({
  key: "confirmModalInfo",
  default: null,
});

export const atom__simpleAlertModalInfo = atom<{
  desc: string[];
  onClickBtn?: () => void;
  btnText?: string;
  btnColor?: string;
} | null>({
  key: "simpleAlertModalInfo",
  default: null,
});

export const atom__loginModalInfo = atom<{
  onLogin?: () => void;
} | null>({
  key: "loginModalInfo",
  default: null,
});

export const atom__termModalInfo = atom<{
  kind: "privacy" | "service" | "location";
  onAgree?: () => void;
} | null>({
  key: "termModalInfo",
  default: null,
});

export const atom__searchOfficeModalInfo = atom<{
  onSelect: (id: string, name: string) => void;
} | null>({
  key: "searchOfficeModalInfo",
  default: null,
});

export const atom__changePwModalInfo = atom<{
  onChange: () => void;
} | null>({
  key: "changePwModalInfo",
  default: null,
});
export const atom__changePhoneModalInfo = atom<{
  onChange: () => void;
} | null>({
  key: "changePhoneModalInfo",
  default: null,
});
export const atom__changeOfficeModalInfo = atom<{
  onChange: () => void;
} | null>({
  key: "changeOfficeModalInfo",
  default: null,
});

export const atom__searchApartModalInfo = atom<{
  title?: string;
  desc?: string;
  descAlert?: string;
  onSelect: (id: string, name: string) => void;
} | null>({
  key: "searchApartModalInfo",
  default: null,
});

export const atom__purchaseBadgeModalInfo = atom<{
  badge: TBadge;
  apart: { id: string; name: string };
  onSuccess: () => void;
} | null>({
  key: "purchaseBadgeModalInfo",
  default: null,
});

export const atom__postMemoModalInfo = atom<{
  postId: string;
  onSave?: () => void;
} | null>({
  key: "postMemoModalInfo",
  default: null,
});

export const atom__userDetailModalInfo = atom<{
  userId: string;
  apartId: string;
  onBlockAfterComplaint?: () => void;
} | null>({
  key: "userDetailModalInfo",
  default: null,
});

export const atom__complaintModalInfo = atom<{
  userId: string;
  onBlockAfterComplaint?: () => void;
} | null>({
  key: "complaintModalInfo",
  default: null,
});

export const atom__menuModalInfo = atom<true | null>({
  key: "menuModalInfo",
  default: null,
});

export const atom__notificationModalInfo = atom<true | null>({
  key: "notificationModalInfo",
  default: null,
});

export const atom__leaveModalInfo = atom<true | null>({
  key: "leaveModalInfo",
  default: null,
});

export const atom__loading = atom<boolean>({
  key: "loading",
  default: false,
});
