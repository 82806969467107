import Axios from "axios";

export type TFetchRes<T = any> = IFetchSuccess<T> | IFetchError;

export interface IFetchSuccess<T = {}> {
  success: true;
  data: T;
}
export interface IFetchError {
  success: false;
  err: {
    msg: string;
    code?: string;
  };
}
// const BASE_URL = process.env.REACT_APP_API_URL;
// const BASE_URL = "http://localhost:3000";
const BASE_URL = "https://api.honeyaptdanji.com";

console.log("baseUrl: ", BASE_URL);
const axios = Axios.create({
  baseURL: BASE_URL,
});

axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("at")}`,
};
axios.defaults.withCredentials = true;

export default axios;
