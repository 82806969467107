import { useLocation, useNavigate } from "react-router-dom";
import BaseButton from "../../components/Button/Base";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { atom__me } from "../../lib/recoil/common.atom";
import useIsMobile from "../../lib/hooks/useIsMobile";
import { useEffect, useMemo, useState } from "react";
import Select from "../../components/Select";
import {
  atom__loginModalInfo,
  atom__searchApartModalInfo,
} from "../../lib/recoil/modal.atom";
import Checkbox from "../../components/Checkbox";
import FakeSearchInput from "../../components/FakeSearchInput";
import BaseButtonOutline from "../../components/Button/Outline";
import { regNumber } from "../../lib/validator";

const PostStep2Page = () => {
  const prevState = useLocation().state;
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [manageFeeAvg, setManageFeeAvg] = useState("");
  const [manageFeeName, setManageFeeName] = useState("");
  const [cntRoom, setCntRoom] = useState("");
  const [cntBath, setCntBath] = useState("");
  const [windowDirection, setWindowDirection] = useState("none");

  const [canNext, setCanNext] = useState(false);

  useEffect(() => {
    setCanNext(
      !!manageFeeAvg &&
        !!manageFeeName &&
        !!cntRoom &&
        !!cntBath &&
        windowDirection !== "none"
    );
  }, [manageFeeAvg, manageFeeName, cntRoom, cntBath, windowDirection]);

  const windowDirectionItems = useMemo(() => {
    return [
      { label: "방향을 선택해주세요.", value: "none" },
      { label: "북향", value: "북향" },
      { label: "북동향", value: "북동향" },
      { label: "동향", value: "동향" },
      { label: "남동향", value: "남동향" },
      { label: "남향", value: "남향" },
      { label: "남서향", value: "남서향" },
      { label: "서향", value: "서향" },
      { label: "북서향", value: "북서향" },
    ];
  }, []);

  return (
    <div
      className="post-register-page-wrapper"
      style={
        isMobile
          ? {
              paddingLeft: "20px",
              paddingRight: "20px",
            }
          : {}
      }
    >
      {!isMobile && (
        <h2 style={{ marginBottom: "40px" }}>매물 등록 요청하기</h2>
      )}
      <div
        className="flex-col-center-center"
        style={{ width: "344px", flex: 1, alignSelf: "center" }}
      >
        <div style={{ width: "100%" }}>
          <div className="flex-row-between-center">
            <label>평균 관리비 (만원)</label>
            <p className="alert-desc">숫자만 입력 가능</p>
          </div>
          <input
            type="number"
            step={1}
            placeholder="숫자만 입력 가능"
            maxLength={10}
            style={{ marginBottom: "20px" }}
            value={manageFeeAvg}
            onChange={(e) => {
              if (e.target.value.length && !regNumber.test(e.target.value))
                return;
              setManageFeeAvg(e.target.value);
            }}
          />

          <div className="flex-row-between-center">
            <label>관리비 항목</label>
            <p className="alert-desc">한글 또는 영문만 입력 가능</p>
          </div>
          <input
            type="text"
            placeholder="공용전기, 엘리베이터"
            style={{ marginBottom: "20px" }}
            value={manageFeeName}
            onChange={(e) => {
              setManageFeeName(e.target.value);
            }}
          />

          <div
            className="flex-row-between-center"
            style={{ gap: "20px", marginBottom: "20px" }}
          >
            <div style={{ flex: 1 }}>
              <label>방 수</label>
              <input
                type="number"
                step={1}
                placeholder="2"
                value={cntRoom}
                onChange={(e) => {
                  setCntRoom(e.target.value);
                }}
                style={{ flex: 1 }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>욕실 수</label>
              <input
                type="number"
                step={1}
                placeholder="1"
                value={cntBath}
                onChange={(e) => {
                  setCntBath(e.target.value);
                }}
                style={{ flex: 1 }}
              />
            </div>
          </div>

          <label>방향</label>
          <Select
            items={windowDirectionItems}
            value={windowDirection}
            onChange={(v) => {
              setWindowDirection(v);
            }}
          />
        </div>

        <div
          className="flex-row-between-center"
          style={{ gap: "20px", marginTop: "100px", width: "100%" }}
        >
          <BaseButtonOutline
            text="이전"
            onClick={() => navigate(-1)}
            style={{ flex: 1, fontSize: "16px" }}
          />
          <BaseButton
            text="다음 (2/4)"
            disabled={!canNext}
            onClick={() => {
              navigate("/post/step3", {
                state: {
                  ...(prevState ?? {}),
                  manageFeeAvg: Number(manageFeeAvg),
                  manageFeeName,
                  cntRoom: Number(cntRoom),
                  cntBath: Number(cntBath),
                  window: windowDirection,
                },
              });
            }}
            style={{ flex: 1, fontWeight: 400, fontSize: "16px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default PostStep2Page;
