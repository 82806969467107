import { TPost } from "../../lib/types/post.type";
import postImageDefault from "../../assets/post-default-116_116.png";
import { convertToPyeong, printCostPreferUk } from "../../lib/util";
import AreaTypeBox from "./AreaTypeBox";
import React from "react";
import useIsMobile from "../../lib/hooks/useIsMobile";

interface IProps {
  post: TPost;
  apartName?: string;
  as3?: string;
  style?: React.CSSProperties;
}
const PostBoxTop = (props: IProps) => {
  const isMobile = useIsMobile();

  return (
    <div className="flex-row-between-center" style={props.style}>
      <img
        src={props.post.thumbnailUrl || postImageDefault}
        style={{
          width: "60px",
          height: "60px",
          marginRight: isMobile ? "6px" : "14px",
        }}
      />
      <div style={{ flex: 1 }}>
        <div
          className="flex-row-between-center"
          style={{ marginBottom: "18px" }}
        >
          <p className="text-medium">
            {props.apartName || props.post.apart.kaptName}
          </p>
          <p className="text-small" style={{ color: "var(--beige)" }}>
            {props.post.saleKind}
          </p>
        </div>
        <div className="flex-row-start-center">
          <p
            className="text-small"
            style={{ color: "var(--border-gray)", flex: 1, textAlign: "left" }}
          >
            {props.as3 || props.post.apart.as3}
          </p>
          <p className="text-big bold">
            {convertToPyeong(props.post.exclusiveArea)}평
          </p>
          <AreaTypeBox
            areaType={props.post.areaType}
            style={{ margin: isMobile ? "0 10px" : "0 20px" }}
          />
          <p className="text-big bold" style={{ color: "var(--brown)" }}>
            {printCostPreferUk(props.post.costOnce)}
            {props.post.costMonth
              ? `/${printCostPreferUk(props.post.costMonth)}`
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PostBoxTop;
