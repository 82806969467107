import { Outlet, useLocation } from "react-router-dom";
import GlobalNavigationBar from "./GNB";

import { LoginModal } from "./Modal/Login";
import ModalSimpleAlert from "./Modal/SimpleAlert";
import ModalSearchOffice from "./Modal/SearchOffice";
import ModalConfirm from "./Modal/Confirm";
import ModalAlert from "./Modal/Alert";
import ModalSuccess from "./Modal/Success";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { atom__me } from "../lib/recoil/common.atom";
import { api_me } from "../api/auth";
import ModalSimpleConfirm from "./Modal/SimpleConfirm";
import ModalChangePw from "./Modal/ChangePw";
import ModalChangePhone from "./Modal/ChangePhone";
import ModalChangeOffice from "./Modal/ChangeOffice";
import ModalSearchApart from "./Modal/SearchApart";
import ModalPurchaseBadge from "./Modal/PurchaseBadge";
import ModalPostMemo from "./Modal/PostMemo";
import ModalTerm from "./Modal/Term";
import ModalUserDetail from "./Modal/UserDetail";
import ModalComplaint from "./Modal/Complaint";
import ModalMenu from "./Modal/Menu";
import ModalNotification from "./Modal/Notification";
import ModalLeave from "./Modal/Leave";
import Loading from "./Modal/Loading";

const Layout = () => {
  const pathName = useLocation().pathname;

  const [r__me, r__setMe] = useRecoilState(atom__me);

  useEffect(() => {
    if (r__me) return;

    api_me()
      .then((res) => {
        if (res?.me) r__setMe(res.me);
      })
      .catch((e) => {
        r__setMe(null);
      });
  }, []);

  useEffect(() => {
    console.log("me: ", r__me);
  }, [r__me]);

  return (
    <div id="root">
      {!pathName.startsWith("/register") && <GlobalNavigationBar />}
      <main style={{ position: "relative" }}>
        <Outlet />
      </main>

      {/* modal */}
      <LoginModal />
      <ModalChangePw />
      <ModalChangePhone />
      <ModalChangeOffice />

      {/* NOTE: 사무소변경 모달보다 사무소검색 모달의 zIndex가 더 높아야 하므로 <ModalChangeOffic />보다 아래에 추가 */}
      <ModalSearchOffice />
      <ModalSearchApart />

      <ModalPurchaseBadge />
      <ModalPostMemo />

      <ModalTerm />

      <ModalUserDetail />
      <ModalComplaint />

      <ModalMenu />
      <ModalLeave />

      <Loading />

      <ModalSimpleConfirm />
      <ModalConfirm />
      <ModalSimpleAlert />
      <ModalAlert />
      <ModalSuccess />
    </div>
  );
};
export default Layout;
