import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import ModalBase from "../Base";
import {
  atom__complaintModalInfo,
  atom__userDetailModalInfo,
} from "../../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import { TUserDetailRes } from "../../../lib/types/user.type";
import { api_userDetail } from "../../../api/user";
import iconClose from "../../../assets/cancel.png";
import iconSiren from "../../../assets/siren.png";
import iconMedalGold from "../../../assets/medal_gold.png";
import iconMedalSilver from "../../../assets/medal_silver.png";
import iconMedalBronze from "../../../assets/medal_bronze.png";
import profileDefault from "../../../assets/profile-default.png";
import PostBoxTop from "../../Post/PostBoxTop";
import BadgeMedal from "../../Badge/BadgeMedal";
import { atom__me } from "../../../lib/recoil/common.atom";

const ModalUserDetailPc = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__userDetailModalInfo
  );

  const r__me = useRecoilValue(atom__me);

  const r__setComplaintModalInfo = useSetRecoilState(atom__complaintModalInfo);

  const [detail, setDetail] = useState<TUserDetailRes | null>(null);

  useEffect(() => {
    if (!r__modalInfo) {
      setDetail(null);
      return;
    }

    api_userDetail(r__modalInfo.userId, r__modalInfo.apartId).then((res) => {
      if (!res) return;
      setDetail(res);
    });
  }, [r__modalInfo]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  if (!detail) return null;

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close} noInner>
      <div
        className="flex-row-center-center"
        style={{
          height: "70%",
          borderRadius: "20px",
          backgroundColor: "#fff",
          position: "relative",
        }}
      >
        {/* left */}
        <div
          className="flex-col-between-center"
          style={{
            width: "400px",
            height: "100%",
            padding: "20px",
            textAlign: "center",
          }}
        >
          {/* left - top */}
          <div className="flex-col-start-center">
            <img
              src={detail.user.profileImgUrl || profileDefault}
              style={{
                width: "66px",
                height: "66px",
                borderRadius: "66px",
                border: "1px solid var(--yellow-dark)",
                marginBottom: "10px",
              }}
            />
            {detail.office.badgeWorth > 0 && (
              <BadgeMedal
                worth={detail.office.badgeWorth}
                size={36}
                style={{ marginBottom: "10px" }}
              />
            )}
            <h2 style={{ color: "var(--brown)", marginBottom: "4px" }}>
              {detail.user.name || detail.office.name}
            </h2>
            <p className="text-small bold" style={{ color: "var(--beige)" }}>
              {detail.office.name} (대표자 {detail.office.ceo})
            </p>
          </div>

          {/* left - bottom */}
          <div>
            <p
              className="text-small bold"
              style={{ color: "var(--border-gray)" }}
            >
              {detail.office.address}
            </p>
            <p
              className="text-small"
              style={{
                color: "var(--border-gray)",
                marginBottom: "20px",
              }}
            >
              중개업등록번호 {detail.office.registerNumber}
            </p>
            {!!detail.user.phone && (
              <h2 style={{ color: "var(--brown)" }}>
                매물담당자: {detail.user.phone}
              </h2>
            )}
            {!!detail.office.hp && (
              <h2 style={{ color: "var(--brown)" }}>
                유선(대표번호): {detail.office.hp}
              </h2>
            )}
          </div>
        </div>

        {/* right */}
        <div
          style={{
            width: "400px",
            height: "100%",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          <p className="text-small bold">등록된 매물</p>

          {/* n건 + 구분선 */}
          <div className="flex-row-center-center">
            <div
              style={{
                padding: "20px 0",
                marginBottom: "20px",
                width: "50%",
                borderBottom: "1px solid var(--border-gray)",
              }}
            >
              <h2 style={{ textAlign: "center" }}>{detail.posts.length}건</h2>
            </div>
          </div>

          {detail.posts.map((post) => {
            return (
              <PostBoxTop
                key={post.id}
                post={post}
                style={{ marginBottom: "24px" }}
              />
            );
          })}
        </div>

        {/* 신고버튼 */}
        {!!r__me && r__me?.id !== r__modalInfo?.userId && (
          <span
            style={{
              position: "absolute",
              padding: "4px",
              top: "10px",
              left: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!r__modalInfo) return;
              r__setComplaintModalInfo({ userId: r__modalInfo.userId });
            }}
          >
            <img src={iconSiren} style={{ width: "28px", height: "28px" }} />
          </span>
        )}

        {/* 닫기버튼 */}
        <span
          style={{
            position: "absolute",
            padding: "4px",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={close}
        >
          <img src={iconClose} style={{ width: "28px", height: "28px" }} />
        </span>
      </div>
    </ModalBase>
  );
};

export default ModalUserDetailPc;
