import { TMe, TRegisterPayload } from "../lib/types/user.type";
import axios, { TFetchRes } from "./axios";

export const api_checkUid = async (uid: string) => {
  const res = await axios.post("/user/check-id", { uid });
  if (!res) return;
  if (!res.data.success) {
    if (res.data.err?.msg) alert(res.data.err?.msg);
    return;
  }

  return res.data.data as { available: boolean; message: string };
};

export const api_sendPhoneCode = async (phone: string) => {
  const res = await axios.post("/user/phonecheck", { phone });
  if (!res) return;
  if (!res.data.success) {
    if (res.data.err?.msg) alert(res.data.err?.msg);
    return;
  }

  return res.data.data as { code?: string };
};

export const api_confirmPhoneCode = async (payload: {
  phone: string;
  code: string;
}) => {
  const res = await axios.post("/user/phonecheck/confirm", payload);
  if (!res) return;
  if (!res.data.success) {
    if (res.data.err?.msg) alert(res.data.err?.msg);
    return;
  }

  return res.data.data as { historyId: string };
};

export const api_register = async (payload: TRegisterPayload) => {
  const res = await axios.post("/user", payload);
  if (!res) return;
  if (!res.data.success) {
    if (res.data.err?.msg) alert(res.data.err?.msg);
    return;
  }

  return res.data.data as {};
};

export const api_loginCheck = async (payload: { uid: string; pw: string }) => {
  const res = await axios.post("/user/login/check", payload);
  if (!res) return;
  return res.data as TFetchRes;
};

export const api_login = async (payload: { uid: string; pw: string }) => {
  const res = await axios.post("/user/login", payload);
  if (!res) return;
  return res.data as TFetchRes<{ accessToken: string; me: TMe }>;
};

export const api_me = async () => {
  const res = await axios.get("/user/me");
  if (!res) return;

  return res.data.data as { me: TMe };
};

export const api_logout = async () => {
  const res = await axios.post("/user/logout", null);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_changePw = async (payload: {
  prevPw: string;
  pw: string;
  pwRe: string;
}) => {
  const res = await axios.post("/user/change/pw", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_changePhone = async (payload: { phone: string }) => {
  const res = await axios.post("/user/change/phone", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_changeProfile = async (payload: { url: string }) => {
  const res = await axios.post("/user/change/profile", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_checkDuplicatedLogin = async () => {
  const res = await axios.post("/user/check-ip");
  if (!res) return;
  return res.data as TFetchRes;
};

export const api_leave = async () => {
  const res = await axios.post("/user/leave");
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};
