import { useRecoilState } from "recoil";
import { useCallback, useEffect, useState } from "react";
import { atom__searchApartModalInfo } from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";
import SearchInputWithoutAttr from "../SearchInputWithoutAttr";
import ModalBaseYellow from "./BaseYellow";
import { api_searchApart } from "../../api/apart";

const ModalSearchApart = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__searchApartModalInfo
  );

  const [resultList, setResultList] = useState<
    { id: string; name: string; address: string }[]
  >([]);
  const [selectedIdx, setSelectedIdx] = useState(-1);

  useEffect(() => {
    if (!r__modalInfo) {
      setResultList([]);
      setSelectedIdx(-1);
      return;
    }
  }, [r__modalInfo]);

  // 검색결과가 바뀔때마다 선택했던거 리셋
  useEffect(() => {
    setSelectedIdx(-1);
  }, [resultList]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const search = (keyword: string) => {
    if (!keyword.length) {
      setResultList([]);
      return;
    }

    api_searchApart(keyword).then((res) => {
      if (!res) return;
      setResultList(res.list);
    });
  };

  // 모달이 다시 열렸을 때 <SearchInputWithoutAttr /> 컴포넌트 내부의 keyword 변수가 초기화될 수 있도록, 모달 닫을 때 하위컴포넌트들 언마운트시켜버림
  if (!r__modalInfo) return null;

  return (
    <ModalBaseYellow
      visible={!!r__modalInfo}
      title={r__modalInfo.title || "아파트 검색"}
      onClickOuter={close}
    >
      <div
        className="flex-col-between-center"
        style={{
          padding: "20px",
          width: "100%",
          flex: 1,
        }}
      >
        <SearchInputWithoutAttr
          onClickSearch={search}
          placeholder="도로명주소 또는 단지명"
        />

        {(!!r__modalInfo.desc || !!r__modalInfo.descAlert) && (
          <div style={{ width: "100%", textAlign: "center", margin: "20px 0" }}>
            {!!r__modalInfo.desc && (
              <p className="text-small">{r__modalInfo.desc}</p>
            )}
            {!!r__modalInfo.descAlert && (
              <p className="text-small" style={{ color: "var(--tomato)" }}>
                {r__modalInfo.descAlert}
              </p>
            )}
          </div>
        )}

        <div className="search-office-result-wrapper">
          {!resultList.length && (
            <div className="flex-row-center-center" style={{ height: "300px" }}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "19.09px",
                  textAlign: "center",
                  color: "#B7BBBD",
                }}
              >
                상단의 검색을 통해
                <br />
                아파트를 검색하고 선택해주세요.
              </p>
            </div>
          )}
          {resultList.map((r, i) => (
            <div
              key={i}
              className={`search-office-result ${
                selectedIdx === i ? "search-office-result-selected" : ""
              }`}
              onClick={() => setSelectedIdx(i)}
            >
              <p className="search-office-result-name">{r.name}</p>
              <p className="search-office-result-address">{r.address}</p>
            </div>
          ))}
        </div>

        <BaseButton
          text="선택완료"
          onClick={() => {
            const selected = resultList[selectedIdx];
            r__modalInfo?.onSelect(selected.id, selected.name);
            close();
          }}
          disabled={selectedIdx === -1}
          style={{ width: "100%" }}
        />
      </div>
    </ModalBaseYellow>
  );
};

export default ModalSearchApart;
