interface IProps {
  areaType: string;
  style?: React.CSSProperties;
}
const AreaTypeBox = (props: IProps) => {
  return (
    <span
      className="text-small"
      style={{
        border: "1px solid var(--tomato)",
        borderRadius: "4px",
        color: "var(--tomato)",
        padding: "3px",
        backgroundColor: "#fff",
        ...(props.style ?? {}),
      }}
    >
      타입 {props.areaType.at(-1)}
    </span>
  );
};

export default AreaTypeBox;
