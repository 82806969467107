import { useMemo, useState } from "react";
import iconLogoFull from "../../assets/logo-full.png";
import { useSetRecoilState } from "recoil";
import { atom__simpleAlertModalInfo } from "../../lib/recoil/modal.atom";
import BaseButton from "../../components/Button/Base";
import ModalSimpleAlert from "../../components/Modal/SimpleAlert";
import { useLocation, useNavigate } from "react-router-dom";
import BaseButtonOutline from "../../components/Button/Outline";

const RegisterCompletePage = () => {
  const navigate = useNavigate();
  const prevState = useLocation().state;

  return (
    <div
      className="flex-col-start-center"
      style={{
        height: "100%",
        width: "100%",
        padding: "20px",
      }}
    >
      {/* inner wrapper: TODO: 반응형일땐 width 100%로 */}
      <div
        className="flex-col-start-center"
        style={{ width: "344px", position: "relative" }}
      >
        <img src={iconLogoFull} style={{ width: "100%" }} />

        <h2 style={{ margin: "20px 0", textAlign: "center" }}>
          회원가입 신청이 성공적으로 완료되었습니다.
        </h2>

        <p
          style={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.71px",
            textAlign: "center",
            margin: "60px 0 30px 0",
          }}
        >
          [접수 완료]
          <br />
          <br />
          감사합니다 <b>{prevState.name || ""}</b> 고객님!
          <br />
          저희 플랫폼 회원가입 및 서비스이용에 진심으로 감사합니다.
          <br />
          멤버십 활성화 최종 승인전 이용료 납부가 필요합니다.
          <br />
          납부 확인 후 최종 승인 시 고객님의 서비스 이용이 진행됩니다.
          <br />
          <br />
          <b>계좌정보</b>
          <br />
          기업은행 111-214383-04-014
          <br />
          예금주 김바론(하우머치닷컴)
          <br />
          <br />
          <b>입금안내</b>
          <br />
          *소속 동, 사무소명, 성함 기재 필수
          <br />
          제한시 전화번호 기재
          <br />
          <br />
          예시)
          <br />
          망포동 하우머치 부동산 홍길동
          <br />-{">"} 망포하우머치홍길동
          <br />
          또는, 010-1234-5678
        </p>

        <BaseButton
          text="처음으로"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>

      <ModalSimpleAlert />
    </div>
  );
};

export default RegisterCompletePage;
