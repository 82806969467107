import { useRecoilValue, useSetRecoilState } from "recoil";
import useIsMobile from "../lib/hooks/useIsMobile";
import { atom__isMobile } from "../lib/recoil/common.atom";
import { atom__termModalInfo } from "../lib/recoil/modal.atom";

const Footer = () => {
  const isMobile = useRecoilValue(atom__isMobile);

  const r__setTermModalInfo = useSetRecoilState(atom__termModalInfo);

  const openTerm = (kind: "service" | "privacy" | "location") => {
    r__setTermModalInfo({ kind });
  };

  if (isMobile)
    return (
      <div className={`footer flex-col-center-center`}>
        <div>
          <p>상호 : 하우머치닷컴 </p>
          <p>사업자등록번호 : 879-41-01126 </p>
          <p>대표 : 김바론 </p>
          <p>개인정보보호책임자 : 김바론 </p>
          <p>통신판매번호 : 2024-수원영통-1599</p>
          <p>
            회사소재지 : 경기도 수원시 영통구 영통로 200번길 21, 현대프라자 3층,
            304-1718호
          </p>
          <p>고객센터번호 : 1800-5664, 070-8670-0537</p>
          <p>업무시간 : 오전 10시 ~ 오후 05시 (월 ~ 금, 공휴일 제외)</p>
          <p>메일 : howmuchdotcom@gmail.com</p>
          <p style={{ marginTop: "10px" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => openTerm("service")}
            >
              [이용약관]
            </span>{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => openTerm("privacy")}
            >
              [개인정보취급방침]
            </span>{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => openTerm("location")}
            >
              [위치기반서비스 이용약관]
            </span>
          </p>
        </div>
      </div>
    );
  else
    return (
      <div className={`footer flex-row-center-center`}>
        <div style={{ marginRight: "20px" }}>
          <p>상호 : 하우머치닷컴 </p>
          <p>사업자등록번호 : 879-41-01126 </p>
          <p>대표 : 김바론 </p>
          <p>개인정보보호책임자 : 김바론 </p>
          <p>통신판매번호 : 2024-수원영통-1599</p>
          <p>
            회사소재지 : 경기도 수원시 영통구 영통로 200번길 21, 현대프라자 3층,
            304-1718호
          </p>
        </div>

        <div>
          <p>고객센터번호 : 1800-5664, 070-8670-0537</p>
          <p>업무시간 : 오전 10시 ~ 오후 05시 (월 ~ 금, 공휴일 제외)</p>
          <p>메일 : howmuchdotcom@gmail.com</p>
          <p style={{ marginTop: "10px" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => openTerm("service")}
            >
              [이용약관]
            </span>{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => openTerm("privacy")}
            >
              [개인정보취급방침]
            </span>{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => openTerm("location")}
            >
              [위치기반서비스 이용약관]
            </span>
          </p>
        </div>
      </div>
    );
};
export default Footer;
